import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TitleNavBar, ContactShow, ArrowDown, IconContainer, ActiveIcon, StarIconBackdrop } from "./Navbar.style";
import TagManager from "../../libs/TagManager"
import {
  clearInfo,
  setDefaultLoadingInfo,
} from "../../redux/actions/action_points_request";
import { getBusinessInfoByMember } from "../../redux/actions/action_business";
import { getCustomFormTitle } from "../../redux/actions/action_custom_form";

export default function PointMenu(props) {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const store_business = useSelector((state) => state.business)
  const { dictionary } = useSelector((state) => state.language)
  const { new_reject, biz_new_reject } = useSelector((state) => state.point);
  const { history } = props
  let business_code = window.location.pathname.split('/')[2]
  let business = store_business.list[business_code] || []

  let isShowTransfer = (business &&
    business.subscribe_date &&
    (business.transfer_p2p || business.transfer_pay));
  let isShowCollectPoints = (business &&
    business.subscribe_date && business.points_request);
  let newReject = new_reject && biz_new_reject.includes(business_code)

  let menuTemplateList = [
    {
      show: isShowCollectPoints,
      type: "collect",
      title: dictionary.collect_point,
      icon: "icon-park",
    },
    {
      show: isShowTransfer,
      type: "transfer",
      title: business.transfer_p2p && business.transfer_pay
        ? `${dictionary.transfer}/${dictionary.pay}`
        : business.transfer_p2p
        ? dictionary.transfer
        : dictionary.pay,
      icon: "icon-arrow-send",
      
    }
  ]
  let list = menuTemplateList.filter((filter) => filter.show);

  useEffect(() => {
    if (business_code) {
      dispatch(getBusinessInfoByMember(business_code))
      dispatch(getCustomFormTitle(business_code))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showMenu = (event) => {
    event.preventDefault();
    TagManager.event(`btn-point_menu_show`)
    setIsOpen(true)
    if (!isOpen)
      document.addEventListener("click", closeMenu);
  }

  const closeMenu = (event) => {
    setIsOpen(false)
    document.removeEventListener("click", closeMenu)
  }

  const onClickHome = () => {
    let pathname = window.location.pathname;
    if (pathname === `/points/${business_code}`) {
      let main_content = document.querySelectorAll("#Main-Content")[0]
      if (main_content) main_content.scrollTop = 0
    } else {
      history.push(`/points/${business_code}`)
    }
  }

  const onClickBusinessBar = ({ name }) => {
    switch (name) {
      case "collect":
        pointsRequest()
        break;
      case "transfer":
        history.push("/points/" + business_code + "/transfer")
        break;
      default:
        window.open(URL, "_blank");
    }
  }

  const pointsRequest = () => {
    dispatch(clearInfo());
    dispatch(setDefaultLoadingInfo())
    history.push("/points/" + business_code + "/collect");
  };

  return (
    <>
      <div hidden={!isOpen}>
        <ContactShow style={{ left: 'calc((100% - 250px)/2)' }}>
          {list.map((value, key) => {
            return (
              <IconContainer
                className="my-2"
                key={key}
                width={100 / list.length}
                name={value.type}
              >
                <div className="d-flex flex-column align-items-center" onClick={() => onClickBusinessBar({ name: value.type })}>
                  <ActiveIcon themeColor={props.themeColor}>
                    <i className={`${value.icon}`}>{value.iconName || ""}</i>
                  </ActiveIcon>
                  {newReject && value.type === 'collect' && (
                    <div className="badge badge-danger badge-circle new_reject_icon">N</div>
                  )}
                  <TitleNavBar themeColor={props.themeColor}>
                    {value.title}
                  </TitleNavBar>
                </div>
              </IconContainer>
            );
          })}
        </ContactShow>
        <ArrowDown style={{ left: (window.innerWidth / 2) - 7 }} />
      </div>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "50px", color: props.themeColor }}
      >
        <StarIconBackdrop
          className="backdrop"
          themeColor={props.themeColor}
          onClick={
            list.length ? showMenu : onClickHome
          }
        >
          <i
            className="icon-star stars"
            style={{
              cursor: "pointer",
            }}
          />
        </StarIconBackdrop>
        {newReject && (
          <div className="badge badge-danger badge-circle new_reject">N</div>
        )}
      </div>
      <TitleNavBar themeColor={props.themeColor}>
        {dictionary.points}
      </TitleNavBar>
    </>
  );
}